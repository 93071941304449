import Vue from 'vue';

Vue.mixin({
  methods: {
    isAdmin() {
      if (!Object.prototype.hasOwnProperty.call(this.$store.state, 'account')) return false;
      if (!Object.prototype.hasOwnProperty.call(this.$store.state.account, 'is_admin')) return false;
      return this.$store.state.account.is_admin === true;
    },
    isLogged() {
      if (!Object.prototype.hasOwnProperty.call(this.$store.state, 'account')) return false;
      if (!Object.prototype.hasOwnProperty.call(this.$store.state.account, 'login')) return false;
      return this.$store.state.account.login.length > 0;
    },
    hasPermission(keyword) {
      if (!this.isLogged()) {
        return false;
      }
      if (this.$store.state.account.is_admin) {
        return true;
      }
      return this.$store.state.account.roles.indexOf(keyword) > -1;
    },
  },
});
