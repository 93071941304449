import Vue from 'vue';
import Vuex from 'vuex';
import VueSuperagent from 'vue-superagent';
import { buildDropdowns } from '@/helpers';

Vue.use(Vuex);
Vue.use(VueSuperagent, {
  baseUrl: '/api',
});

const defaultAccount = {
  display_name: '',
  id: 0,
  login: '',
  is_admin: false,
  roles: [],
};

export default new Vuex.Store({
  state: {
    app: {
      defaultRoute: '/',
      currencies: [],
      dropdowns: {},
    },
    account: defaultAccount,
  },
  mutations: {
    signIn(state, account) {
      state.account = account;
    },
    signOut(state) {
      state.account = defaultAccount;
    },
    setCurrencies(state, currencies) {
      state.app.currencies = currencies;
    },
    setDropdowns(state, dropdowns) {
      state.app.dropdowns = dropdowns;
    },
  },
  actions: {
    fetchCurrencies(context) {
      Vue.superagent
        .get('/currency')
        .then((res) => {
          context.commit('setCurrencies', res.body.currencies);
        })
        .catch((err) => {
          alert(`Failed to fetch currencies: ${err.response.text}`);
        });
    },
    fetchDropdowns(context) {
      Vue.superagent
        .get('/select/download')
        .then((res) => {
          context.commit('setDropdowns', buildDropdowns(res.body.download));
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch dropdowns: ${err.response.text}`);
        });
    },
  },
  modules: {},
});
