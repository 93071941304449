import Vue from 'vue';

Vue.filter('capitalize', (str) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
});

Vue.filter('longdate', (timestamp) => {
  const date = new Date(Number(timestamp) * 1000);
  const d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const m = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1);
  const y = date.getFullYear();
  return `${d}.${m}.${y}`;
});

Vue.filter('shortdate', (timestamp) => {
  const date = new Date(Number(timestamp) * 1000);
  const d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const m = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1);
  const y = date.getFullYear().toString().slice(-2);
  return `${d}.${m}.${y}`;
});

Vue.filter('isodate', (dateString) => {
  if (typeof dateString !== 'string' && dateString.length !== 10) return dateString;
  return dateString.slice(0, 10).split('-').reverse().join('.');
});

Vue.filter('shorttime', (timestamp) => {
  const date = new Date(Number(timestamp) * 1000);
  const hh = (date.getHours()) < 10 ? `0${date.getHours()}` : (date.getHours());
  const mm = (date.getMinutes()) < 10 ? `0${date.getMinutes()}` : (date.getMinutes());
  return `${hh}:${mm}`;
});

Vue.filter('time', (timestamp) => {
  const date = new Date(Number(timestamp) * 1000);
  const hh = (date.getHours()) < 10 ? `0${date.getHours()}` : (date.getHours());
  const mm = (date.getMinutes()) < 10 ? `0${date.getMinutes()}` : (date.getMinutes());
  const ss = (date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds());
  return `${hh}:${mm}:${ss}`;
});

Vue.filter('format', (num) => (Number(num).toLocaleString('cs-CZ')));

Vue.filter('round', (num) => (Number(num).toLocaleString('cs-CZ', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})));

Vue.filter('formatRecipe', (num) => (Number(num).toLocaleString('cs-CZ', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 5,
})));

Vue.filter('mask', (str) => (str.replace(/./g, '*')));
